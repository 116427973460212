import { grommet } from "grommet";
import { generate } from "grommet/themes";
import { deepMerge, normalizeColor } from "grommet/utils";

export const trtTheme = deepMerge(grommet, generate(20, 6), {
  global: {
    size: {
      "avatar-small": "28px",
      "legend-circle": "12px",
      tooltip: "180px",
      "notification-open": "320px",
      "notification-closed": "45px",
    },
    colors: {
      "not-found": "#ffbfbd",
      selected: {
        dark: "brand",
        light: "neutral-3",
      },
      focus: "tab-active",
      border: {
        light: "border-light",
        dark: "#616161",
      },
      "border-light": "#dfe1e5",
      brand: "#90EE90",
      text: {
        light: "#333333",
      },
      "graph-1": "#ffa600",
      "graph-2": "#006d90",
      "graph-4": "#e67f83",
      "status-warning": "#d0e429",
      "status-critical": "#C62828",
      "status-ok": "#3EB489",
      "neutral-3": "#002D62",
      liabilities: "#ff404d",
      "neutral-5": "#FECAD5",
      "neutral-1": "#DDE59B",
      "dark-1": "#262626",
      "app-header": {
        light: "neutral-3",
        dark: "#161616",
      },
      "menu-icon": {
        light: "neutral-3",
        dark: "light-2",
      },
      "tab-hover": { light: "light-2", dark: "dark-4" },
      notification: {
        light: "light-3",
        dark: "dark-2",
      },
      "form-control": {
        light: "neutral-3",
        dark: "brand",
      },
      card: {
        light: "white",
        dark: "#202020",
      },
      drop: {
        dark: "#626262",
        light: "white",
      },
      "confirmation-modal": {
        light: "white",
        dark: "dark-1",
      },
      "tab-active": {
        light: "neutral-3",
        dark: "dark-2",
      },
      "add-account": {
        light: "neutral-3",
        dark: "text",
      },
      logo: {
        light: "neutral-3",
        dark: "white",
      },
      assets: "#56b34a",
      "meter-background": {
        light: "light-3",
        dark: "dark-4",
      },
      "onboarding-fill": {
        light: "light-3",
        dark: "dark-1",
      },
      "onboarding-background": {
        light: "light-1",
        dark: "#161616",
      },
    },
    drop: {
      border: {
        radius: "4px",
      },
      background: "drop",
    },
    elevation: {
      light: {
        footer: "0px -4px 4px rgba(0,0,0,0.20)",
      },
      dark: {
        none: "none",
        xsmall: "0px 2px 2px rgba(0, 0, 0, 0.14)",
        small: "0px 4px 4px rgba(0, 0, 0, 0.14)",
        medium: "0px 6px 8px rgba(0, 0, 0, 0.14)",
        large: "0px 8px 16px rgba(0, 0, 0, 0.14)",
        xlarge: "0px 12px 24px rgba(0, 0, 0, 0.14)",
        footer: "0px -4px 4px rgba(0,0,0,0.14)",
      },
    },
    font: {
      family: "Source Sans Pro",
    },
    selected: {
      background: {
        light: "neutral-3",
        dark: "dark-2",
      },
    },
  },
  anchor: {
    color: {
      light: "neutral-3",
      dark: "light-4",
    },
  },
  button: {
    border: {
      radius: "8px",
    },
    padding: {
      vertical: "8px",
    },
  },
  checkBox: {
    color: "form-control",
  },
  radioButton: {
    check: {
      color: "form-control",
    },
    color: "form-control",
    gap: "xsmall",
  },
  icon: {
    size: {
      small: "18px",
    },
  },
  heading: {
    level: {
      4: {
        small: {
          maxWidth: "1200px",
        },
        medium: {
          maxWidth: "1200px",
        },
      },
      3: {
        small: {
          maxWidth: "1200px",
        },
        medium: {
          maxWidth: "1200px",
        },
      },
    },
    font: {
      family: "Source Sans Pro",
    },
    extend: ({ level }) => {
      if (level === 1) {
        return "font-weight: 900";
      } else if (level === 4 || level === 5) {
        return "text-transform: uppercase";
      }
      return `
        b {
          font-weight: 900;
        }
      `;
    },
  },
  layer: {
    overlay: {
      background: "rgba(0, 0, 0, 0.6)",
    },
    border: {
      radius: "10px",
    },
  },
  paragraph: {
    large: {
      maxWidth: "600px",
    },
    medium: {
      maxWidth: "600px",
    },
  },
  rangeInput: {
    thumb: {
      color: "form-control",
    },
  },
  tabs: {
    gap: "small",
  },
  tab: {
    active: {
      background: "tab-active",
      color: undefined,
    },
    background: {
      light: "light-3",
      dark: "dark-3",
    },
    color: "text-weak",
    border: undefined,
    pad: "xsmall",
    margin: undefined,
    hover: {
      color: undefined,
      extend: ({ background, theme }) =>
        background !== "tab-active"
          ? {
              background: normalizeColor("tab-hover", theme),
              color: theme.global.colors.text,
            }
          : "",
    },
    extend: ({ theme }) => `
      border-radius: ${theme.global.borderSize.large};
      font-weight: bold;
      text-transform: uppercase;
      min-width: 68px;
      justify-content: center;
      align-items: center;
      height: 36px;
      box-sizing: border-box;
      padding: 0 8px;

      > span {
        font-size: ${theme.text.small.size}
      }
    `,
  },
  grommet: {
    extend: `
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    `,
  },
  formField: {
    label: {
      weight: "bold",
    },
    focus: {
      border: {
        color: "tab-active",
      },
    },
  },
  table: {
    body: {
      pad: "8px",
      border: "bottom",
    },
    header: {
      pad: "8px",
      background: "tab-active",
      extend: `
        font-weight: bold;
      `,
    },
    extend: `
      border-collapse: collapse;
      min-width: 240px;
      border-radius: 5px 5px 5px 5px;
      overflow: hidden;
      width: 100%;
    `,
  },
});
