import React, { useCallback, useContext } from "react";
import {
  Anchor,
  Box,
  Button,
  ResponsiveContext,
  Paragraph,
  Heading,
} from "grommet";
import { LogoFull } from "../components";

export const Faq = () => {
  const size = useContext(ResponsiveContext);
  const onSignUpClick = useCallback(() => {
    window.open(`https://app.theretirementtracker.com/access`);
  }, []);
  return (
    <Box flex>
      <Box
        tag="header"
        background="neutral-3"
        align="center"
        pad={{ horizontal: "large", vertical: "medium" }}
        direction="row"
        justify="between"
      >
        <Anchor href="/" target="_blank">
          <LogoFull size="medium" />
        </Anchor>
        <Box direction="row" align="center" gap="large">
          <Anchor
            href="https://app.theretirementtracker.com/login"
            label="Sign in"
            size={size !== "small" ? "large" : undefined}
          />
          <Button
            size={size === "small" ? "small" : undefined}
            primary
            label="Sign up"
            onClick={onSignUpClick}
          />
        </Box>
      </Box>
      <Box flex overflow="auto" pad={{ horizontal: "large", bottom: "xlarge" }}>
        <Heading level={2} margin={{ bottom: "none" }}>
          Frequently Asked Questions
        </Heading>
        <Paragraph color="text-weak">Last updated October 26th, 2021</Paragraph>
        <Heading level={3}>
          1. Why is my Institution failing to retrieve the balance after
          successful Plaid connection?
        </Heading>
        <Paragraph margin="none">
          Check if your institution is enabled for two-factor authentication.
          There are known issues with Plaid and their ability to get the balance
          for these institutions. Unfortunately, the only way to integrate with
          the institution for now is to disable two-factor authentication.
        </Paragraph>
        <Heading level={3}>
          2. Why my performance inside the investment group is different from
          the dashboard?
        </Heading>
        <Paragraph margin="none">
          To make sure we can validate your activity, we only consider automated
          investment accounts for your performance inside the investment group.
        </Paragraph>
        <Heading level={3}>
          3. What can The Retirement Tracker do with the access to my
          institutions?
        </Heading>
        <Paragraph margin="none">
          When we connect through Plaid, we only have access to read-only
          information about your accounts, mainly your balance. It is not
          possible for us to make any withdraws and/or deposits.
        </Paragraph>
      </Box>
    </Box>
  );
};
