import React, { useCallback, useContext } from "react";
import {
  Anchor,
  Box,
  Button,
  ResponsiveContext,
  Paragraph,
  Heading,
} from "grommet";
import { LogoFull } from "../components";

export const Legal = () => {
  const size = useContext(ResponsiveContext);
  const onSignUpClick = useCallback(() => {
    window.open(`https://app.theretirementtracker.com/access`);
  }, []);
  return (
    <Box flex>
      <Box
        tag="header"
        background="neutral-3"
        align="center"
        pad={{ horizontal: "large", vertical: "medium" }}
        direction="row"
        justify="between"
      >
        <Anchor href="/" target="_blank">
          <LogoFull size="medium" />
        </Anchor>
        <Box direction="row" align="center" gap="large">
          <Anchor
            href="https://app.theretirementtracker.com/login"
            label="Sign in"
            size={size !== "small" ? "large" : undefined}
          />
          <Button
            size={size === "small" ? "small" : undefined}
            primary
            label="Sign up"
            onClick={onSignUpClick}
          />
        </Box>
      </Box>
      <Box
        flex
        overflow="auto"
        pad={{ horizontal: "xlarge", bottom: "xlarge" }}
      >
        <Heading level={2} id="pp" margin={{ bottom: "none" }}>
          Privacy Policy
        </Heading>
        <Paragraph color="text-weak">Last updated March 17th, 2021</Paragraph>
        <Paragraph>
          This Privacy Policy of The Retirement Tracker, Inc. (
          <b>"Retirement Tracker"</b> or <b>"we"</b>) describes how we collect,
          use, and protect your data in connection with your use of the Services
          (as defined below).
        </Paragraph>
        <Paragraph>
          By visiting our Site, by using the Services, or by in any manner
          using, installing or downloading any Retirement Tracker software or
          applications associated with the Services,{" "}
          <b>
            you accept our practices described in this Privacy Policy, and you
            consent to our collection, use and disclosure of your information,
            including personally identifiable information, as described in this
            Privacy Policy
          </b>
          . If you do not wish to agree to the practices and uses described in
          this Privacy Policy, please do not use the Services.
        </Paragraph>
        <Heading level={3} color="text-weak">
          1. Applicability of this Privacy Policy
        </Heading>
        <Paragraph>
          This Privacy Policy applies to your use of Retirement Tracker's
          website located at{" "}
          <a
            href="https://theretirementtracker.com"
            target="_blank"
            rel="noreferrer"
          >
            https://theretirementtracker.com
          </a>
          , including as available at{" "}
          <a
            href="https://app.theretirementtracker.com"
            target="_blank"
            rel="noreferrer"
          >
            https://app.theretirementtracker.com
          </a>
          , (collectively, the <b>"Site"</b>), Retirement Tracker's application
          on any of your devices (the <b>"App"</b>) or any of or related
          services (collectively, with the Site and the App, the{" "}
          <b>"Services"</b>). Individual users of the Services are sometimes
          referred to in this Privacy Policy as <b>"users."</b> This Privacy
          Policy is designed to tell you what information we gather from you in
          connection with your use of the Services, and how we may use and
          disclose that information. This Privacy Policy is incorporated into
          and subject to any terms of use or other agreements, between you and
          us or our affiliates, relating to your use of the Services.
        </Paragraph>
        <Paragraph>
          Except as expressly stated herein, this Privacy Policy does not apply
          to any third party applications or technologies that integrate with
          our Services, or any other third party products, services, or
          businesses, or to third party websites that you access via links or
          otherwise while using the Services (<b>"Third Party Services"</b>).
          This Privacy Policy does not apply to data collected from, or provided
          by you to, Third Party Services, and instead such data is subject to
          the practices of the provider(s) of the applicable Third Party
          Services. You should review the privacy policies of such Third Party
          Services (and any other applicable terms and conditions) to determine
          how your data will be used before sharing any of your data with them.
        </Paragraph>
        <Heading level={3} color="text-weak">
          2. Data We Collect From You
        </Heading>
        <Paragraph>
          In connection with your use of the Services, we may receive various
          types of information related to you, as well as information you
          provide us about others. Sometimes you provide us with this data and
          sometimes data about you is collected automatically. To the extent
          data is associated with an identified or identifiable natural person
          and is protected as personal information (or substantially equivalent
          terms) under applicable data protection laws, it is referred to in
          this Privacy Policy as "<b>Personal Information.</b>" You acknowledge
          and agree we may collect, process, store, access, and disclose
          Personal Information disclosed by you to facilitate the provision of
          Services and related support for the Services in the manner described
          in this Privacy Policy.
        </Paragraph>
        <Paragraph>
          When you create, update, and/or an account for the Services with us,
          you provide us with data, including Personal Information, about
          yourself, including, without limitation:
        </Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>your name</li>
          <li style={{ margin: "3px 0" }}>your email address(es)</li>
          <li style={{ margin: "3px 0" }}>your device's unique identifier</li>
          <li style={{ margin: "3px 0" }}>your user name</li>
          <li style={{ margin: "3px 0" }}>your password</li>
          <li style={{ margin: "3px 0" }}>
            any other information that you elect to share or provide us access
            to, including Personal Information that may identify you
            individually
          </li>
        </ul>
        <Paragraph>
          You are also agreeing to share financial information with us,
          including, but not limited to, your account credentials, transactional
          history, account numbers, and balances/limits as well as general
          identity data including the name(s) and address(es) of all account
          holder(s). You are enabling us to interact with and through your
          financial institutions on your behalf and with your consent, which
          will take place, in part, through Plaid, a Third Party Provider, as
          described further below in this section.
        </Paragraph>
        <Paragraph>
          We also may collect Personal Information that you post, upload, store,
          display, transmit, or submit through the Services in any other manner.
          Although we will treat such information consistent with this Privacy
          Policy, we are not responsible for the content of any information,
          including Personal Information, that you provide to us, and by using
          the Services, you assume full responsibility for obtaining, and you
          represent that you have obtained, all necessary consents and
          permissions to provide such information to us.
        </Paragraph>
        <Paragraph>
          Additionally, if and when you use the Services to make payments of any
          kind, you may provide us (or our third party payment processors)
          additional Personal Information including financial information (such
          as your credit/debit card information, address, date of birth, or
          other information). We endeavor to store very little, if any,
          payment-related financial information that we collect from you and
          instead, such payment-related financial information is typically
          stored by our third party payment processors. We encourage you to
          review their privacy policies and to contact them directly if you have
          questions relating to your data, including Personal Information, as it
          relates to such payments. Although we reserve the right to select
          different or additional payment processors at our discretion, our
          payment processors as of the date of this Privacy Policy include{" "}
          <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">
            Stripe
          </a>
          .
        </Paragraph>
        <Paragraph>
          Retirement Tracker may also collect other information from you related
          to your use of the Services and your interactions with our Services
          (while this information may not typically contain Personal
          Information, we are not responsible for the content of such
          information). This information includes any such information that you
          affirmatively provide to Retirement Tracker, and may include the
          following:
        </Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>
            <b>Location Information</b>. Certain of our Third Party Providers
            may request permission to and track location-based information from
            your mobile device, either continuously or while you are using the
            Services, to provide location-based services, and we may receive
            this information from our Third Party Providers. If you wish to
            change our access or permissions, you may do so in your device's
            settings.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Log Data</b>. Like most websites and web-based technology
            services, our servers may automatically collect data when you access
            or use the Services and record it in log files. Such may include
            your Internet Protocol (IP) address, Internet service provider
            (ISP), geographic location, browser type and settings, information
            about browser plugins, language preference, default email
            application, referring/exit websites, operating system, date and
            time stamp, cookie data, and certain user activities.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Services Activity</b>. Retirement Tracker may collect details
            regarding your activity on the Services, such as search queries,
            amount of time spent viewing pages, and other performance and usage
            data.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Technical Data</b>. Retirement Tracker may collect technical
            data, such as information about devices accessing the Services,
            including the type of device, device settings, operating system,
            application software, peripherals, and unique device identifiers,
            phone number, country, location, and any other data you choose to
            provide. Retirement Tracker does not intentionally relate this to
            any individual user of the Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Cookie Information</b>. The Services may also use cookies and
            similar tracking technologies of third parties, which may collect
            information about your use of the Services. Cookies are small text
            files sent by us to your computer or mobile device for later
            retrieval. They are unique to your account or your browser.
            Session-based cookies last only while your browser is open and are
            automatically deleted when you close your browser. Persistent
            cookies last until you or your browser deletes them or until they
            expire. If you do not wish to have cookies placed on your computer
            or in local storage, you may adjust your web browser settings
            accordingly to block cookies or to alert you when cookies are sent.
            However, restricting cookies may impede your ability to use the
            Services or certain features of the Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Third Party Services</b>. You may elect to use certain Third
            Party Services that we make available in connection with your use of
            the Services. As noted above, information you provide to such Third
            Party Services is subject to the privacy policies and practices of
            the provider of the applicable Third Party Service. Additionally,
            once enabled, it is possible that the Third Party Services may share
            certain data with Retirement Tracker to effectuate integration
            between our Services and theirs. You should check the privacy
            settings and notices of any Third Party Services you elect to use in
            connection with the Services to understand what data may be
            disclosed to Retirement Tracker. We may receive data regarding your
            credentials for and use of the applicable Third Party Services, such
            as your user name, your unique identifier, and your information
            transmitted from or made available with permissions by such Third
            Party Services (e.g., account profile, gender, age range, language,
            geographic region, etc.).
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Additional Information Provided to Retirement Tracker</b>.
            Retirement Tracker receives data when submitted to or through our
            Services, or if you contact us (whether by email, telephone, written
            correspondence, web-based forms, or otherwise), request support,
            interact with our social media accounts, or otherwise communicate
            with Retirement Tracker.
          </li>
        </ul>
        <Paragraph>
          Retirement Tracker uses Plaid Technologies, Inc. (<b>"Plaid"</b>) to
          gather your data from financial institutions. By using our service,
          you grant Retirement Tracker and Plaid the right, power, and authority
          to act on your behalf to access and transmit your personal information
          as well as account information maintained by third-party financial
          institutions with which you have a legally-binding customer
          relationship (<b>"Account Information"</b>). This information may
          include account balances, transactions and holdings from the linked
          financial institutions. You agree to your personal and financial
          information, as listed in Retirement Tracker Privacy Policy being
          transferred, stored, and processed by Plaid in accordance with the
          Plaid Privacy Policy which is available at{" "}
          <a href="https://plaid.com/legal/" target="_blank" rel="noreferrer">
            https://plaid.com/legal/
          </a>
          . By choosing to link your bank account to aggregate and analyze
          Account Information, you expressly authorize and direct Retirement
          Tracker, on your behalf, to electronically retrieve Account
          Information.
        </Paragraph>
        <Heading level={3} color="text-weak">
          3. No Sensitive Personal Information; No Children's Data{" "}
        </Heading>
        <Paragraph>
          Retirement Tracker does not intentionally collect, process, or store,
          and we request that you do not post, upload, store, display, transmit,
          or submit Sensitive Personal Information on or through the Services,
          except to the extent directly requested in connection with the
          relevant aspect of the Services.{" "}
          <b>"Sensitive Personal Information"</b> includes, but is not limited
          to, government-issued identification numbers; consumer reports;
          background checks; any code or password that could be used to gain
          access to personal accounts (other than your password to your
          Retirement Tracker account); genetic data or biometric data; any
          Personal Information revealing racial or ethnic origin, political
          opinions, religious or philosophical beliefs, or trade union
          membership; or data concerning health or sex life or sexual
          orientation. or similar information. Retirement Tracker is not
          responsible and will not be liable for any loss or damages you or
          another individual may experience due to your disclosure of Sensitive
          Personal Information while using the Services.
        </Paragraph>
        <Paragraph>
          The Services are not directed to or intended for children, and
          Retirement Tracker does not intentionally collect, process, or store
          through the Services any Personal Information from any person under 13
          years of age. In the event we discover we have inadvertently
          collected, processed, or stored any Personal Information from a person
          under 13 years of age without verifiable parental consent, we will
          promptly take the appropriate steps to delete such data or seek the
          necessary verifiable parental consent for that collection in
          compliance with the Children's Online Privacy Protection Act (
          <b>"COPPA"</b>). We request that users not provide us with any
          Personal Information of any person under 13 years of age.
        </Paragraph>
        <Heading level={3} color="text-weak">
          4. How and Why We Use Data{" "}
        </Heading>
        <Paragraph>
          We use the information collected from you, including Personal
          Information collected as described in this Privacy Policy, to
          administer the Services and to communicate with you about the
          Services. Our use of information from you may include the following:
        </Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>
            <b>Providing and Improving the Services.</b> To make the Services
            available, to manage user requests and interactions with the
            Services (e.g., login and authentication, modifying settings, etc.),
            to facilitate hosting and back-end infrastructure for our platform,
            including by our Third Party Providers, to analyze and monitor
            usage, and to monitor and address service performance, security, and
            technical issues.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Third Party Applications/Services.</b> With your permission,
            third-party applications or services may access your Personal
            Information. We use standard OAuth (open authorization) to enable
            you to give permission to share your Personal Information with other
            websites and services, such as LinkedIn, Facebook and Twitter (e.g.,
            when you agree to a pop-up requesting you to allow another
            application to access your account information). We also use OAuth
            to allow us to share information about you that is stored by us
            without sharing your security credentials.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Improving the Services.</b> To test features, manage landing
            pages, heat mapping, traffic optimization, data analysis and
            research, including profiling and the use of machine learning and
            other techniques over your data and in some cases using Third Party
            Providers to do this.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Support Services.</b> To respond to support requests and
            otherwise provide support for and resolve problems with the
            Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Communications.</b> To send service, technical, and
            administrative emails, messages, and other communications.
            Service-related communications about changes to the Services and
            important Services-related notices, such as maintenance and security
            announcements, are essential to delivery of the Services and you
            cannot opt-out of these communications. Marketing communications, if
            any, about new product features, offerings, and other news about
            Retirement Tracker are optional; you have the choice whether or not
            to receive them and you may opt out by using the unsubscribe
            mechanism in such communications.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Analytics.</b> In connection with our use of cookies described
            above, we may also use Third Party Providers to allow tracking
            technologies and remarketing services on the Services through the
            use of first-party cookies and third-party cookies, to, among other
            things, analyze and track users' use of the Services, and to better
            understand online activity related to our Site. By accessing the
            Services, you consent to the collection and use of your information
            by these Third Party Providers. You are encouraged to review such
            vendors' privacy policies and contact them directly for responses to
            your questions.
          </li>{" "}
          If you do not want any information to be collected and used by
          tracking technologies, you can install and/or update your settings to
          control such use.
          <li style={{ margin: "3px 0" }}>
            <b>Account Management.</b> To contact you in connection with account
            management, feedback, and other administrative matters related to
            your account with us and the Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Security Purposes.</b> To help prevent and investigate security
            issues and abuse.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Legal Obligations.</b> To comply with legal obligations as
            required by applicable law, legal process, or regulations as
            described above.
          </li>
        </ul>
        <Heading level={3} color="text-weak">
          5. How We Share and Disclose Data{" "}
        </Heading>
        <Paragraph>
          Except as described in this Privacy Policy, Retirement Tracker will
          not use or disclose your Personal Information for any purpose other
          than to the extent reasonably necessary to perform the Services and
          related support for the Services. As a matter of policy, absent your
          express consent, we do not sell or rent information about you, and we
          will not disclose information about you in a manner inconsistent with
          this Privacy Policy except as required by law or government
          regulation.
        </Paragraph>
        <Paragraph>
          We cooperate with law enforcement inquiries, as well as other third
          parties, to enforce laws such as those regarding intellectual property
          rights, fraud and other personal rights. WE CAN (AND YOU AUTHORIZE US
          TO) DISCLOSE ANY INFORMATION ABOUT YOU TO LAW ENFORCEMENT, OTHER
          GOVERNMENT OFFICIALS OR ANY OTHER THIRD PARTY THAT WE, AT OUR SOLE
          DISCRETION, BELIEVE NECESSARY OR APPROPRIATE IN CONNECTION WITH AN
          INVESTIGATION OF FRAUD, INTELLECTUAL PROPERTY INFRINGEMENT OR OTHER
          ACTIVITY THAT IS ILLEGAL OR MAY EXPOSE US, OR YOU, TO LIABILITY.
        </Paragraph>
        <Paragraph>
          Subject to the above paragraphs, we may share or disclose information
          about you as follows:
        </Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>
            <b>With Your Consent.</b> Retirement Tracker may disclose your data,
            including Personal Information, to third parties when we have your
            express consent to do so. This includes any information that you
            post to your user page or otherwise on our Site, App and/or via the
            Services – by publishing such information via the Site, App or
            Services, you consent to Retirement Tracker's disclosure of this
            information.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>By Law or to Protect Rights.</b> If we believe the release of
            information about you is necessary to respond to legal process, to
            investigate or remedy potential violations of our policies, or to
            protect the rights, property, and safety of others, we may share
            your information as permitted or required by applicable law, rule,
            or regulation. This includes exchanging information with other
            entities for fraud protection and credit risk reduction.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Rendering the Services.</b> Retirement Tracker employees and
            contractors may have access to your data on a need to know and
            confidential basis to the extent necessary to render the Services
            and related support for the Services. We require such employees and
            contractors to treat your information consistent with this Privacy
            Policy. If in using the Services you direct or authorize us to share
            certain information with a third party or user of the Services, then
            this may include providing your name, email and investment
            information to the third party for purposes of facilitating the
            purchase. At the time of sharing such information, you authorize
            Retirement Tracker to provide your contact information to the third
            party of the Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Interactions with Other Users; Postings.</b> If you interact with
            other users of the Services, those users may see identifying
            information and descriptions of your activity that you make
            available to them and/or authorize us to share with specific users
            or specific agreed-upon groups of users. For example, our Services
            may provide you with the option to share information (including
            investment information), comments and other content among a "pod" or
            other group of users of the Services, each of whom has consented to
            inclusion in the pod or group. If you choose to participate in such
            pod or group, your comments, other postings, investment activity,
            name and potentially other Personal Information will be available to
            the other members of your pod or group. At each time of sharing such
            information in such context, you authorize Retirement Tracker to
            provide such information to the other user(s) of the Services
            included in your pod or group. If you post comments, contributions
            or other content in any other way connected to the Services, your
            posts may be viewed by other users and may be publicly distributed
            outside the Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Affiliates.</b> We may share your information with our
            affiliates, in which case we will require those affiliates to honor
            this Privacy Policy. Affiliates include our parent company and/or
            any subsidiaries (if any), companies with an ownership interest in
            Retirement Tracker, joint venture partners, or other companies that
            we control or that are under common control with us.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Third Party Providers.</b> As described above, Retirement Tracker
            engages third parties to process data in support of delivering of
            the Services (<b>"Third Party Providers"</b>). We may share your
            data, including Personal Information, with Third Party Service
            Providers (e.g., email services, platform hosting, cloud computing
            services, data storage and processing facilities) to the limited
            extent necessary to let them perform business functions and services
            for us or on our behalf in connection with the provision of the
            Services. For example, servers used by Retirement Tracker in
            connection with providing the Services are not physically located at
            our facilities, but rather are managed and located at a third-party
            Infrastructure-as-a-Service provider (an <b>"IAAS provider"</b>). We
            have taken commercially reasonable steps to choose a professional
            and reputable IAAS provider and to ensure that such IAAS provider
            and our other Third Party Providers use appropriate security
            measures in light of the risks and nature of the data being
            protected and consistent with industry norms. Still, it is
            impossible to guarantee that the security measures taken by our
            Third Party Providers will be adequate in all circumstances, and by
            using the Services, you understand and agree that we have no
            liability for the action, behaviors or failings of such Third Party
            Providers. Although we reserve the right to select different or
            additional Third Party Providers, our Third Party Providers include,
            as of the date of this Privacy Policy, vendors such as Plaid
            Technologies, Inc., and Firebase.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Third Party Services.</b> You may enable or permit integrations
            with or use of Third Party Services in connection with the Services.
            When enabled, Retirement Tracker may share certain data with such
            Third Party Services as requested to effectuate the integration,
            including data regarding your credentials related to the Services.
            As mentioned above, Third Party Services are not owned or controlled
            by Retirement Tracker and third parties that have been granted
            access to your data may have their own policies and practices for
            its collection and use; you should check the privacy settings and
            notices of these Third Party Services to understand their privacy
            practices.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Changes to Retirement Tracker's Business.</b> If Retirement
            Tracker engages in a merger, acquisition, bankruptcy, dissolution,
            reorganization, sale of some or all of its assets or stock,
            financing, public offering of securities, acquisition of all or a
            portion of our business, a similar transaction or proceeding, or
            steps in contemplation of such activities (e.g. due diligence),
            Retirement Tracker may share or disclose data in connection
            therewith, subject to standard confidentiality obligations.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Aggregated or De-Identified Data.</b> If any data is aggregated
            or de-identified so it is no longer reasonably associated with an
            identified or identifiable natural person or household (in the case
            of aggregated data) or no longer capable of being associated with or
            relinked with any identifiable natural person or household (in the
            case of deidentified data), we may use or disclose such aggregated
            or de-identified data for any purpose. For example, we may share
            aggregated or de-identified data with prospects or partners for
            business or research purposes, such as statistical analysis, to
            research trends and predictive analysis, or to develop or improve
            the Services.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Enforcement of Agreements.</b> Retirement Tracker may disclose
            data to ensure compliance with and to enforce contractual or legal
            obligations with respect to the Services and our business, including
            any applicable lease agreements.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Protection of Rights.</b> Retirement Tracker may disclose data to
            protect and defend our rights and property, including intellectual
            property rights, and to ensure compliance with applicable laws and
            enforce third party rights, including intellectual property and
            privacy rights.
          </li>
          <li style={{ margin: "3px 0" }}>
            <b>Safety and Security.</b> Retirement Tracker may disclose data to
            protect your safety and security; to protect the safety, security
            and property of our users; and to protect the safety, security, and
            property of Retirement Tracker and our employees, agents,
            representatives, and contractors.
          </li>
        </ul>
        <Heading level={3} color="text-weak">
          6. Retention{" "}
        </Heading>
        <Paragraph>
          Generally, we maintain all information you submit to us until you
          affirmatively change or delete such information. This allows us to
          help ensure full functionality of the Services. To dispose of Personal
          Information, we may anonymize it, delete it, or take other appropriate
          steps. Your data, potentially including Personal Information, may
          persist in copies made for backup and business continuity purposes for
          additional time.
        </Paragraph>
        <Heading level={3} color="text-weak">
          7. Security Measures{" "}
        </Heading>
        <Paragraph>
          Retirement Tracker maintains physical, technical, and administrative
          procedures to protect the data we collect and to secure it from
          improper of unauthorized use. We work hard to protect data in our
          custody and control from loss, misuse, and unauthorized access, use,
          disclosure, modification, or destruction, and to use industry-standard
          security measures in order to ensure an appropriate level of security
          in light of reasonably available methods in relation to the risks and
          nature of the information we collect.
        </Paragraph>
        <Paragraph>Please remember:</Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>
            You provide information to us, including your Personal Information,
            at your own risk.
          </li>
          <li style={{ margin: "3px 0" }}>
            No data transmission over the Internet is guaranteed to be 100%
            secure, and we cannot guarantee that unauthorized access, hacking,
            data losses, or other breaches will never occur.
          </li>
          <li style={{ margin: "3px 0" }}>
            You are responsible for protecting your account information related
            to the Services, including any applicable credentials, log-ins,
            passwords, etc. and for ensuring that they are not used by others to
            access the Services.
          </li>
        </ul>
        <Heading level={3} color="text-weak">
          8. International Data Transfers{" "}
        </Heading>
        <Paragraph>
          Retirement Tracker primarily processes and stores data in connection
          with the Services in the United States. However, it is possible that
          data may be processed in other countries by our Third Party Providers.
          We will take measures to ensure that your Personal Information remains
          protected to the standards described in this Privacy Policy and any
          such transfers comply with applicable data protection laws. In certain
          circumstances, courts, law enforcement agencies, regulatory agencies
          or security authorities in those other countries may be entitled to
          access your Personal Information.
        </Paragraph>
        <Heading level={3} color="text-weak">
          9. California-Specific Notices{" "}
        </Heading>
        <Paragraph>
          Pursuant to Section 1798.83 of the California Civil Code, California
          residents have the right to request from a business with whom the
          California resident has an established business relationship, certain
          information with respect to the types of personal information the
          business shares with third parties for direct marketing purposes by
          such third party and the identities of the third parties with whom the
          business has shared such information during the immediately preceding
          calendar year. We do not disclose your Personal Information to third
          parties' for any third parties' direct marketing purposes. If you
          reside in California, you can prevent future disclosures for direct
          marketing purposes of your Personal Information, at no charge, by
          indicating to us your intent to opt out of such disclosures in a
          message addressed to the email address or physical mailing address
          provided under the "Contact Us" heading below.
        </Paragraph>
        <Heading level={3} color="text-weak">
          10. Other Jurisdiction-Specific Notices{" "}
        </Heading>
        <Paragraph>
          <i>Nevada Residents</i>
        </Paragraph>
        <Paragraph>
          Nevada residents may have the right under applicable law to opt out of
          the sale of their Personal Information that we have collected or will
          in the future collect. If you are a Nevada resident and wish to make
          such a request, please contact us at the contact information provided
          below in this Privacy Policy.
        </Paragraph>
        <Paragraph>
          <i>EU Residents</i>
        </Paragraph>
        <Paragraph>
          Although we do not direct our website specifically toward residents of
          the European Union (<b>"EU"</b>), some EU residents' data may be
          collected through marketing channels or by virtue of our users' use of
          our Services. Collection and storage of any EU resident's data by us
          is minimal and incidental.
        </Paragraph>
        <Paragraph>
          Notwithstanding the foregoing, if you are an EU resident and would
          like to request that your data be securely removed from our systems,
          however collected, please send an email with proof of EU residency to{" "}
          <a
            target="_blank"
            href="mailto: contact@theretirementtracker.com"
            rel="noreferrer"
          >
            contact@theretirementtracker.com
          </a>
          . We will endeavor to remove all relevant data, so long as that
          removal is technically feasible, does not impact the legitimate
          accounting or business practices of our customers, and does not
          violate other regulatory or legal standards with which we must comply.
          We will also cooperate with our customers in good faith to address any
          requests they receive or that may impact them directly.
        </Paragraph>
        <Heading level={3} color="text-weak">
          11. Enforcement{" "}
        </Heading>
        <Paragraph>
          Retirement Tracker will actively monitor its relevant privacy and
          security practices to verify adherence to this Privacy Policy. Any
          agents, contractors, service providers, or other third parties subject
          to this Privacy Policy that Retirement Tracker determines to be in
          violation of this Privacy Policy or applicable data protection laws
          will be subject to disciplinary action up to and including termination
          of applicable services. Please contact us immediately at the contact
          information provided under the "Contact Us" heading below if you
          believe there has been a material violation of this Privacy Policy.
        </Paragraph>
        <Heading level={3} color="text-weak">
          12. Changes to this Privacy Policy{" "}
        </Heading>
        <Paragraph>
          We reserve the right to make changes to this Privacy Policy from time
          to time, in whole or in part, at our sole discretion, at any time
          without prior notice by posting an updated version of this Privacy
          Policy on the Site or when you access the Services. When we do, we
          will revise the "last updated" date at the beginning of the Privacy
          Policy. We will make commercially reasonable efforts to notify you by
          email, through the Services (at log-in or otherwise), and/or by
          posting a prominent notice on our Site that our Privacy Policy has
          been updated. Any changes, modifications, or updates to this Privacy
          Policy will become effective immediately upon posting. By continuing
          to use the Services, you agree to be bound to changes we make to this
          Privacy Policy. If you disagree with the changes to this Privacy
          Policy, your only remedy is to discontinue use of the Services and
          deactivate your account.
        </Paragraph>
        <Heading level={3} color="text-weak">
          13. Contact{" "}
        </Heading>
        <Paragraph>
          Please do not hesitate to contact us with any questions, complaints,
          or requests with respect to your Personal Information, this Privacy
          Policy, and/or our privacy practices.
        </Paragraph>
        <Paragraph>
          You may contact us at{" "}
          <a
            target="_blank"
            href="mailto: contact@theretirementtracker.com"
            rel="noreferrer"
          >
            contact@theretirementtracker.com
          </a>
          .
        </Paragraph>
        <Heading level={2} id="tou" margin={{ bottom: "none" }}>
          Terms of Use
        </Heading>
        <Paragraph color="text-weak">Last updated March 17th, 2021</Paragraph>
        <Paragraph>
          The Retirement Tracker, Inc. (<b>"Retirement Tracker," "we," "us,"</b>{" "}
          or <b>"our"</b>) welcomes you. These Terms of Use constitute a legally
          binding agreement made between you, whether personally or on behalf of
          any entity (<b>"you"</b>) and Retirement Tracker. We provide you
          access to our website, available at{" "}
          <a
            href="https://theretirementtracker.com"
            target="_blank"
            rel="noreferrer"
          >
            https://theretirementtracker.com
          </a>
          , including as available at{" "}
          <a
            href="https://app.theretirementtracker.com"
            target="_blank"
            rel="noreferrer"
          >
            https://app.theretirementtracker.com
          </a>
          , (collectively, the <b>"Website"</b>), and our mobile application
          (the <b>"App"</b> and, collectively with the Website, the{" "}
          <b>"Platform"</b>), subject to the following Terms of Use, which may
          be updated by us from time to time without notice to you. By accessing
          the Platform, you acknowledge that you have read, understood, and
          agree to be legally bound by these Terms of Use and our Privacy
          Policy, which is hereby incorporated by reference and can be found at
          [insert link to privacy policy] (collectively, this "Agreement"). If
          you do not agree to any of these terms, then please do not use the
          Platform.
        </Paragraph>
        <Paragraph>
          The sections below titled "Dispute Resolution - Binding Arbitration"
          and "Class Action Waiver" contain a binding arbitration agreement and
          class action waiver. They affect your legal rights. Please read them.
        </Paragraph>
        <Paragraph>
          Capitalized terms not defined in these Terms of Use shall have the
          meaning set forth in our Privacy Policy.
        </Paragraph>
        <Heading level={3} color="text-weak">
          1. Description of Platform; Restrictions
        </Heading>
        <Paragraph>
          We provide Visitors and Registered Users with access to the Platform
          as described below.
        </Paragraph>
        <Paragraph>
          <b>Visitors</b>
        </Paragraph>
        <Paragraph>
          "Visitors" as the term implies, are people who do not register with
          us, but want to explore the Platform. No login is required for
          Visitors. Visitors can: (i) view all publicly-available content on the
          Platform; and (ii) e-mail us.
        </Paragraph>
        <Paragraph>
          <b>Registered Users</b>
        </Paragraph>
        <Paragraph>
          <b>"Registered Users"</b> are individuals that have registered for an
          account on the Platform (whether paid or unpaid) whereby they use the
          services made available on the Platform (the <b>"Services"</b>).
          Registered Users can do all the things that Visitors can do, and: (i)
          create, access, manage, and update their own personal accounts on the
          Platform; (ii) interact with certain other users in agreed upon "pods"
          or groups, including sharing their investment activity; and (iii) sign
          up for alerts and other notifications.
        </Paragraph>
        <Paragraph>
          Retirement Tracker is under no obligation to accept any individual as
          a Registered User and may accept or reject any registration in its
          sole and complete discretion. In addition, Retirement Tracker may
          deactivate or suspend any account at any time, including, without
          limitation, if it determines that a Registered User has violated this
          Agreement. We reserve the right, in our sole and absolute discretion,
          to deny you access to the Platform, or any portion of the Platform,
          without notice and without reason. For avoidance of doubt, your access
          to and use of this Platform shall be governed by this Agreement, and
          the provision of Retirement Tracker's Services through the Platform.
        </Paragraph>
        <Paragraph>
          <b>Premium Service</b>
        </Paragraph>
        <Paragraph>
          Registered Users, upon creation of an account with Retirement Tracker,
          will have to choose between the basic model (the{" "}
          <b>"Basic Subscription"</b>) or the pro model, (the{" "}
          <b>"Premium Subscription"</b>). The Premium Subscription shall offer
          the ability to manage more than one automated financial institutions
          and interact with other Registered Users in Pods (as further explained
          below). Fees applicable to the different levels of subscription are
          provided to Registered Users at the time of registration. Retirement
          Tracker reserves the right to limit the number of automated financial
          institutions managed under the Premium Subscription.
        </Paragraph>
        <Heading level={3} color="text-weak">
          2. Community Guidelines
        </Heading>
        <Paragraph>
          Our community, like any community, functions best when our users
          follow a few simple rules. By accessing the Platform, you agree to
          comply with these community guidelines (the{" "}
          <b>"Community Guidelines"</b>) and that:
        </Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>
            You will comply with all applicable laws in your use of the Platform
            and will not use the Platform for any unlawful purpose;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not access or use the Platform to collect any market
            research for a competing business;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not impersonate any person or entity or falsely state or
            otherwise misrepresent your affiliation with a person or entity;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not interfere with, or attempt to interrupt the proper
            operation of, the Platform through the use of any virus, device,
            information collection or transmission mechanism, software or
            routine, or access or attempt to gain access to any Content (as
            defined below), data, files, or passwords related to the Platform
            through hacking, password or data mining, or any other means;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not decompile, reverse engineer, or disassemble any
            software or other products or processes accessible through the
            Platform;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not cover, obscure, block, or in any way interfere with any
            advertisements and/or safety features on the Platform;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not use any robot, spider, scraper, or other automated
            means to access the Platform for any purpose without our express
            written permission; provided, however, we grant the operators of
            public search engines permission to use spiders to copy materials
            from the public portions of the Platform for the sole purpose of and
            solely to the extent necessary for creating publicly-available
            searchable indices of the materials, but not caches or archives of
            such materials;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not engage in illegal insider trading as defined by the
            Securities and Exchange Commission (the <b>"SEC"</b>);
          </li>
          <li style={{ margin: "3px 0" }}>
            You are not under 13 years of age. Persons under the age of 13 are
            not permitted to use or register for an account or otherwise use the
            Platform;
          </li>
          <li style={{ margin: "3px 0" }}>
            You will not take any action that imposes or may impose (in our sole
            discretion) an unreasonable or disproportionately large load on our
            technical infrastructure; and
          </li>
          <li style={{ margin: "3px 0" }}>
            If you find something that violates our Community Guidelines, please
            let us know, and we'll review it.
          </li>
        </ul>
        <Heading level={3} color="text-weak">
          3. Sign-in Name; Password; Unique Identifiers
        </Heading>
        <Paragraph>
          If you wish to register for the Platform you will be prompted to
          create an account, which may include a sign-in name (
          <b>"Sign-In Name"</b>), a password (<b>"Password"</b>), and perhaps
          certain additional information that will assist in authenticating your
          identity when you log-in in the future (<b>"Unique Identifiers"</b>).
          When creating your account, you must provide true, accurate, current,
          and complete information. Each Sign-In Name and corresponding Password
          can be used by only one user. You are solely responsible for the
          confidentiality and use of your Sign-In Name, Password, and Unique
          Identifiers, as well as for any use, misuse, or communications entered
          through the Platform using one or more of them. You will promptly
          inform us of any need to deactivate a Password or Sign-In Name, or
          change any Unique Identifier. We reserve the right to delete or change
          your Password, Sign-In Name, or Unique Identifier at any time and for
          any reason and shall have no liability to you for any loss or damage
          caused by such action.
        </Paragraph>
        <Paragraph>
          By using the Services, you represent and warrant that:(1) all account
          creation information you submit will be true, accurate, and complete,
          and such remains current at the time you use the Services and
          Platform; (2) you have the legal capacity to agree to these Terms of
          Use, and you agree to comply with these Terms of Use; (3) you are not
          a minor in the jurisdiction in which you reside; (4) you will not
          access the Platform or use the Services through automated or non-human
          means, whether through a bot, script or otherwise; (5) you will not
          use the Platform for any illegal or unauthorized purpose; and (6) your
          use of the Platform or the Services will not violate any applicable
          law or regulation.
        </Paragraph>
        <Heading level={3} color="text-weak">
          4. Intellectual Property
        </Heading>
        <Paragraph>
          The Platform contains material, such as videos, photographs, software,
          text, graphics, images, sound recordings, and other material provided
          by or on behalf of Retirement Tracker (collectively referred to as the{" "}
          <b>"Content"</b>). The Content may be owned by us or by third parties.
          The Content is protected under both United States and foreign laws.
          Unauthorized use of the Content may violate copyright, trademark, and
          other laws. You have no rights in or to the Content, and you will not
          use the Content except as permitted under this Agreement. No other use
          is permitted without prior written consent from us. You must retain
          all copyright and other proprietary notices contained in the original
          Content on any copy you make of the Content. You may not sell,
          transfer, assign, license, sublicense, or modify the Content or
          reproduce, display, publicly perform, make a derivative version of,
          distribute, or otherwise use the Content in any way for any public or
          commercial purpose. The use or posting of the Content on any other
          website or in a networked computer environment for any purpose is
          expressly prohibited.
        </Paragraph>
        <Paragraph>
          The trademarks, service marks, and logos of Retirement Tracker (the{" "}
          <b>"Retirement Tracker Trademarks"</b>) used and displayed on the
          Platform are registered and unregistered trademarks or service marks
          of Retirement Tracker. Other company, product, and service names
          located on the Platform may be trademarks or service marks owned by
          others (the <b>"Third-Party Trademarks,"</b> and, collectively with
          Retirement Tracker Trademarks, the <b>"Trademarks"</b>). Nothing on
          the Platform should be construed as granting, by implication,
          estoppel, or otherwise, any license or right to use the Trademarks,
          without our prior written permission specific for each such use. Use
          of the Trademarks as part of a link to or from any site is prohibited
          unless establishment of such a link is approved in advance by us in
          writing. All goodwill generated from the use of Retirement Tracker
          Trademarks inures to our benefit.
        </Paragraph>
        <Paragraph>
          Elements of the Platform are protected by trade dress, trademark,
          unfair competition, and other state and federal laws and may not be
          copied or imitated in whole or in part, by any means, including, but
          not limited to, the use of framing or mirrors. None of the Content may
          be retransmitted without our express, written consent for each and
          every instance.
        </Paragraph>
        <Paragraph>
          <b>Contribution License</b>
        </Paragraph>
        <Paragraph>
          The Platform may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Platform, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,{" "}
          <b>"Contributions"</b>). By posting your Contributions to any part of
          the Platform or making Contributions accessible to the Platform by
          linking your account from the Platform to any of your social
          networking accounts, you automatically grant, and you represent and
          warrant that you have the right to grant, to us an unrestricted,
          unlimited, irrevocable, perpetual, non-exclusive, transferable,
          royalty-free, fully-paid, worldwide right, and license to host, use,
          copy, reproduce, disclose, sell, resell, publish, broadcast, retitle,
          archive, store, cache, publicly perform, publicly display, reformat,
          translate, transmit, excerpt (in whole or in part), and distribute
          such Contributions (including, without limitation, your image and
          voice) for any purpose, commercial, advertising, or otherwise, and to
          prepare derivative works of, or incorporate into other works, such
          Contributions, and grant and authorize sublicenses of the foregoing.
          The use and distribution may occur in any media formats and through
          any media channels.
        </Paragraph>
        <Paragraph>
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </Paragraph>
        <Paragraph>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Platform. You
          are solely responsible for your Contributions to the Platform and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </Paragraph>
        <Paragraph>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Platform; and (3) to pre-screen or delete any Contributions at any
          time and for any reason, without notice. We have no obligation to
          monitor your Contributions.
        </Paragraph>
        <Paragraph>
          <b>Copyright Infringements</b>
        </Paragraph>
        <Paragraph>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Platform and/or the
          Services infringes upon any copyright you own or control, please
          immediately notify us using the contact information provided below (a{" "}
          <b>"Notification"</b>) by following the steps set forth in our
          Copyright and Intellectual Property Policy (<b>"Copyright Policy"</b>
          ). As described in more detail in the Copyright Policy, a copy of your
          Notification will be sent to the person who posted or stored the
          material addressed in the Notification. Please be advised that,
          pursuant to applicable law, you may be held liable for damages if you
          make material misrepresentations in a Notification made under the
          Copyright Policy. Thus, if you are not sure that material located on
          or linked to by the Platform and/or the Services infringes your
          copyright, you should consider first contacting an attorney.
        </Paragraph>
        <Heading level={3} color="text-weak">
          5. No Offers or Reliance; No Investment or Financial Advice
        </Heading>
        <Paragraph>
          Visitors should note that no information set forth in areas of the
          Platform that Visitors have access to (the <b>"Visitor User Areas"</b>
          ) should be construed as investment or financial advice. The Visitor
          User Areas are limited to the dissemination of general information
          pertaining to Retirement Tracker's early retirement tracking services,
          together with access to additional investment-related information,
          publications, and links. Accordingly, the publication of information
          on the Visitor User Areas on the Internet should not be construed by
          any client and/or prospective client/investor as Retirement Tracker's
          solicitation to effect, or attempt to effect, transactions in
          securities, or the rendering of personalized investment advice for
          compensation, over the Internet.
        </Paragraph>
        <Paragraph>
          Retirement Tracker is neither a law firm nor an accounting firm, and
          no portion of the Platform should be interpreted as legal, accounting,
          or tax advice. To the extent that past performance is available
          through the Platform, past performance is not indicative of future
          results, and no representation is being made that any investment will
          or is likely to achieve profits or losses similar to those achieved in
          the past, or that significant losses will be avoided.
        </Paragraph>
        <Paragraph>
          No material available through the Platform shall be used or considered
          as an offer to sell or a solicitation of any offer to buy the
          securities or services of any of our affiliated entities. Offers can
          only be made where lawful under, and in compliance with, applicable
          law.
        </Paragraph>
        <Paragraph>
          Please remember that different types of investments involve varying
          degrees of risk, and there can be no assurance that the future
          performance of any specific investment or investment strategy
          (including those undertaken or recommended by Retirement Tracker),
          will be profitable or equal to any historical performance levels.
        </Paragraph>
        <Paragraph>
          Investments discussed on the Platform, if any, may not be suitable for
          all investors. Investors should make their own investment decisions
          based upon their own financial objectives and financial resources, and
          should obtain independent investment and tax advice before deciding to
          invest. By way of example, any information shared within an investment
          pod (a <b>"Pod"</b>) is not intended as investment advice being
          offered by Retirement Tracker, and any decision to join a Pod, or any
          investment approaches or actions taken as a result of your involvement
          in a Pod should be an investor's own decision and based on their own
          financial needs and priorities. Investments discussed on the Platform
          ultimately may generate positive returns, and other investments made
          in these asset classes or geographic regions, but not discussed on the
          Platform, may generate negative returns, or vice versa. It should not
          be assumed that investments made for any particular account will match
          the performance or character of the investments discussed on the
          Platform, or that the returns of any accounts managed by Retirement
          Tracker will equal the performance of the investments discussed on the
          Platform. Investors may experience materially different results.
        </Paragraph>
        <Heading level={3} color="text-weak">
          6. No Inside Information; No Guaranteed Results
        </Heading>
        <Paragraph>
          Retirement Tracker obtains information from a wide variety of
          publicly-available sources. Retirement Tracker does not have, nor does
          it claim to have, sources of inside or private information. The
          recommendations developed by Retirement Tracker in connection with its
          services are based upon the professional judgment of Retirement
          Tracker, and Retirement Tracker cannot and does not guarantee the
          results of any recommendations. The recommendations and information
          shared within Pods are based on the judgment and actions of the other
          investors in your Pod, and are in no way meant to be construed as
          inside or private information or guarantees of certain results. If any
          Registered User is suspected of engaging in illegal insider trading as
          defined by the SEC, or sharing information in their Pod that could be
          construed as illegal insider trading, they will immediately be denied
          access to the Platform and their account suspended.
        </Paragraph>
        <Heading level={3} color="text-weak">
          7. Forward-Looking Statements
        </Heading>
        <Paragraph>
          The Platform contains certain "forward-looking statements," which may
          be identified by the use of such words as "believe," "expect,"
          "anticipate," "should," "planned," "estimated," "potential," and other
          similar terms. Examples of forward-looking statements include, but are
          not limited to, estimates with respect to financial condition, results
          of operations, and the success or lack of success of Retirement
          Tracker's investment process or strategy. All are subject to various
          factors, including, but not limited to, general and local economic
          conditions, changing levels of competition within certain industries
          and markets, changes in interest rates, changes in legislation or
          regulation, and other economic, competitive, governmental, regulatory,
          and technological factors affecting Retirement Tracker's operations
          that could cause actual results to differ materially from projected
          results.
        </Paragraph>
        <Heading level={3} color="text-weak">
          8. Positions and Recommendations
        </Heading>
        <Paragraph>
          Certain portions of the Platform may contain a discussion of, and/or
          provide access to, Retirement Tracker's (and those of other investment
          and non-investment professionals) and members of your Pod's positions
          and/or recommendations as of a specific prior date. Due to various
          factors, including changing market conditions, such discussion may no
          longer be reflective of current positions and/or recommendations.
          Moreover, no Registered User should assume that any such discussion
          serves as the receipt of, or a substitute for, personalized advice
          from Retirement Tracker or from any other investment professional.
        </Paragraph>
        <Paragraph>9. Rankings and Rating Services</Paragraph>
        <Paragraph>
          Rankings and/or recognition by unaffiliated rating services and/or
          publications should not be construed by a Registered User as a
          guarantee that he/she will experience a certain level of results if
          Retirement Tracker is engaged, or continues to be engaged, to provide
          early retirement tracking services, nor should they be construed as
          current or past endorsements of Retirement Tracker by any of its
          Registered Users. Rankings published by magazines and others generally
          base their selections exclusively on information prepared and/or
          submitted by the recognized adviser. Moreover, with regard to any
          performance information contained on the Platform, directly or
          indirectly, users should note that past results are not indicative of
          future results.
        </Paragraph>
        <Heading level={3} color="text-weak">
          10. Communications With and Submissions To Us
        </Heading>
        <Paragraph>
          Although we encourage you to e-mail us, we do not want you to, and you
          should not, e-mail us any content that contains confidential
          information. With respect to all e-mails and communications you send
          to us, including, but not limited to, ratings, feedback, questions,
          comments, suggestions, and the like, we shall be free to use any
          ratings data, ideas, concepts, know-how, or techniques contained in
          your communications for any purpose whatsoever, including but not
          limited to, the development, production, and marketing of products and
          services that incorporate such information without compensation or
          attribution to you.
        </Paragraph>
        <Heading level={3} color="text-weak">
          11. Modifications and Interruptions
        </Heading>
        <Paragraph>
          We reserve the right to change, modify, or remove the contents of the
          Platform, or the functionality of the Services at any time or for any
          reason at our sole discretion without notice. However, we have no
          obligation to update any information on our Platform. We also reserve
          the right to modify or discontinue all or part of the Services without
          notice at any time. An example of the above includes Retirement
          Tracker's right to reduce the frequency that users of the Basic
          Subscription have their automated financial institutions updated
          automatically on the Platform, in our sole discretion, if you do not
          use the Platform for a period of a month or more. Except as expressly
          provided in a written agreement executed by us, we will not be liable
          to you or any third party for any modification, price change,
          suspension, or discontinuance of the Platform or the Services.
        </Paragraph>
        <Paragraph>
          We cannot guarantee the Platform and the Services will be available at
          all times. We may experience hardware, software, or other problems or
          need to perform maintenance related to the Platform, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify the Platform
          or the Services at any time or for any reason without notice to you.
          You agree that we have no liability whatsoever for any loss, damage,
          or inconvenience caused by your inability to access or use the
          Platform or the Services during any downtime or discontinuance of the
          Platform or the Services. Nothing in these Terms of Use will be
          construed to obligate us to maintain and support the Platform or the
          Services or to supply any corrections, updates, or releases in
          connection therewith.
        </Paragraph>
        <Paragraph>
          Retirement Tracker shall not be liable in any manner or be deemed to
          be in breach of these Terms of Use because of any delay in performing
          or any failure to perform any of Retirement Tracker's obligations
          under these Terms of Use if the delay or failure was due to any cause
          beyond Retirement Tracker's reasonable control (which shall include,
          but not be limited to government actions, war, fire, explosion, flood,
          acts of terrorism, import or export regulations or embargoes, labor
          disputes or inability to obtain or a delay in obtaining supplies of
          goods or labor).
        </Paragraph>
        <Heading level={3} color="text-weak">
          12. No Warranties; Limitation of Liability
        </Heading>
        <Paragraph>
          EXCEPT AS EXPRESSLY STATED IN THESE TERMS OF SERVICE OR IN ANY
          SEPARATE WRITTEN AGREEMENT BETWEEN YOU AND RETIREMENT TRACKER, THE
          PLATFORM AND THE SERVICES ARE PROVIDED ON AN AS-IS AND AS- AVAILABLE
          BASIS. YOU AGREE THAT YOUR USE OF THE PLATFORM AND THE SERVICES WILL
          BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
          DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
          PLATFORM AND THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
          LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, AND NON-INFRINGEMENT, EXCEPT AS EXPRESSLY STATED
          IN THESE TERMS OF SERVICE. EXCEPT AS EXPRESSLY STATED IN THESE TERMS
          OF SERVICE, WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE PLATFORM'S CONTENT OR THE CONTENT OF
          ANY WEBSITES LINKED TO THE PLATFORM, AND WE WILL ASSUME NO LIABILITY
          OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
          PLATFORM, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
          AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (4) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM OR
          THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH
          MAY BE TRANSMITTED TO OR THROUGH THE PLATFORM BY ANY THIRD PARTY,
          AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
          ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
          CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
          PLATFORM. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH THE PLATFORM, ANY HYPERLINKED WEBSITE, OR ANY
          WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
          ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
          RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-
          PARTY PROVIDERS OF PRODUCTS OR SERVICES. YOU ACKNOWLEDGE THAT
          RETIREMENT TRACKER DOES NOT REPRESENT OR WARRANT THAT THE CONTENT AND
          ANY OTHER DATA OR INFORMATION PROVIDED THROUGH THE PLATFORM WILL BE
          ACCURATE OR COMPLETE.
        </Paragraph>
        <Paragraph>
          IN NO EVENT WILL RETIREMENT TRACKER OR OUR DIRECTORS, EMPLOYEES, OR
          AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
          INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
          ARISING FROM YOUR USE OF THE PLATFORM OR THE SERVICES, EVEN IF WE HAVE
          BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </Paragraph>
        <Paragraph>
          NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
          LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM
          OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE GREATER OF (1) THE
          AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD
          PRIOR TO ANY CAUSE OF ACTION ARISING OR (2) $100.
        </Paragraph>
        <Paragraph>
          THIS SECTION APPLIES TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
          LAW.
        </Paragraph>
        <Paragraph>
          Some jurisdictions do not allow the exclusion of certain warranties.
          Therefore, some of the above limitations on warranties in this section
          may not apply to you. Nothing in these terms of use shall affect any
          non-waivable statutory rights that apply to you.
        </Paragraph>
        <Heading level={3} color="text-weak">
          13. External Sites
        </Heading>
        <Paragraph>
          The Platform may contain links to third-party websites (
          <b>"External Sites"</b>). These links are provided solely as a
          convenience to you and not as an endorsement by us of the content on
          such External Sites. The content of such External Sites is developed
          and provided by others. You should contact the site administrator or
          webmaster for those External Sites if you have any concerns regarding
          such links or any content located on such External Sites. We are not
          responsible for the content of any linked External Sites and do not
          make any representations regarding the content or accuracy of
          materials on such External Sites. You should take precautions when
          downloading files from all websites to protect your computer from
          viruses and other destructive programs. If you decide to access linked
          External Sites, you do so at your own risk.
        </Paragraph>
        <Heading level={3} color="text-weak">
          14. Payment Processing; Refunds Limited
        </Heading>
        <Paragraph>
          You may make purchases in connection with your use of the Services,
          including the purchase of the Premium Subscription or extra services
          offered for an additional fee (the <b>"Paid Services"</b>). We use a
          third-party payment processor (the <b>"Payment Processor"</b>) to bill
          you through a payment account linked to your account on the Services
          for use of the Paid Services. The processing of payments will be
          subject to the terms, conditions and privacy policies of the Payment
          Processor in addition to these Terms of Use. We are not responsible
          for error by the Payment Processor. By making a purchase through the
          Services, you agree to pay us, through the Payment Processor, all
          charges as set forth at the time of your purchase in accordance with
          the applicable payment terms and you authorize us, through the Payment
          Processor, to charge your payment provider. You agree to make payment
          using that selected payment method. We reserve the right to correct
          any errors or mistakes that it makes even if it has already requested
          or received payment.
        </Paragraph>
        <Paragraph>
          Although we reserve the right to select different or additional
          payment processors at our discretion, our Payment Processor as of the
          date of these Terms of Use is Stripe.
        </Paragraph>
        <Paragraph>
          You agree to provide current, complete, and accurate purchase and
          account information to the Payment Processor for all purchases you
          make in connection with the Services. You further agree to promptly
          update account and payment information, including email address,
          payment method, and payment card expiration date with the Payment
          Processor. Sales tax will be added to the price of purchases as deemed
          required by us. All sales are final and no refund will be issued.
        </Paragraph>
        <Paragraph>
          We reserve the right to refuse any order placed through the Website at
          our sole discretion. We may, in our sole discretion, limit or cancel
          quantities purchased per user or per purchase.
        </Paragraph>
        <Paragraph>
          If we terminate your access to the Platform as contemplated by this
          Agreement, you will be not be entitled for any refund as previously
          paid, unless decided by Retirement Tracker in its sole discretion.
        </Paragraph>
        <Paragraph>
          Except as set forth in the foregoing paragraph, all purchases made
          through the Services are final and no refunds are available.
        </Paragraph>
        <Heading level={3} color="text-weak">
          15. Indemnification
        </Heading>
        <Paragraph>
          You agree to defend, indemnify, and hold us and our officers,
          directors, employees, agents, successors, licensees, licensors, and
          assigns harmless from and against any damages, liabilities, losses,
          expenses, claims, actions, and/or demands, including, without
          limitation, reasonable legal and accounting fees, arising or resulting
          from: (i) your breach of this Agreement; (ii) your misuse of the
          Content or the Platform; (iii) your Contributions, (iv) any overt
          harmful act by you toward any other user of the Platform or the
          Services with whom you connected via the Platform and/or (v) your
          violation of any third-party rights, including without limitation any
          copyright, trademark, property, publicity, or privacy right. We shall
          provide notice to you of any such claim, suit, or proceeding and shall
          assist you, at your expense, in defending any such claim, suit, or
          proceeding. We reserve the right to assume the exclusive defense and
          control (at your expense) of any matter that is subject to
          indemnification under this section. In such case, you agree to
          cooperate with any reasonable requests assisting our defense of such
          matter.
        </Paragraph>
        <Heading level={3} color="text-weak">
          16. Compliance with Applicable Laws
        </Heading>
        <Paragraph>
          We make no claims concerning whether the Content may be downloaded,
          viewed, or be appropriate for use outside of the United States. If you
          access the Platform or the Content from outside of the United States,
          you do so at your own risk. Whether inside or outside of the United
          States, you are solely responsible for ensuring compliance with the
          laws of your specific jurisdiction.
        </Paragraph>
        <Heading level={3} color="text-weak">
          17. Termination of the Agreement
        </Heading>
        <Paragraph>
          We reserve the right, in our sole discretion, to restrict, suspend, or
          terminate this Agreement and your access to all or any part of the
          Platform, at any time and for any reason without prior notice or
          liability. We reserve the right to change, suspend, or discontinue all
          or any part of the Platform at any time without prior notice or
          liability. Sections 4-24 shall survive the termination of this
          Agreement.
        </Paragraph>
        <Heading level={3} color="text-weak">
          18. Controlling Law
        </Heading>
        <Paragraph>
          This Agreement and any action related thereto will be governed by the
          laws of the State of California without regard to its conflict of laws
          provisions.
        </Paragraph>
        <Heading level={3} color="text-weak">
          19. Dispute Resolution - Binding Arbitration
        </Heading>
        <Paragraph>
          If you are a U.S. resident, by entering in these Terms of Use,{" "}
          <b>
            <u>
              you and Retirement Tracker agree to resolve any disputes between
              you and us relating to these Terms of Use or the Services through
              final and binding arbitration
            </u>
          </b>{" "}
          by a single arbitrator, except as set forth below. This includes
          disputes arising out of or relating to interpretation or application
          of this "Dispute Resolution – Binding Arbitration" section, including
          its enforceability, revocability, or validity. BY AGREEING TO
          ARBITRATE, EACH PARTY IS GIVING UP ITS RIGHT TO GO TO COURT AND HAVE
          ANY DISPUTE HEARD BY A JUDGE OR JURY. The following provisions of this
          Section 19 apply to U.S. residents who enter these Terms of Use by
          using the Services or otherwise.
        </Paragraph>
        <Paragraph>
          For U.S. residents, the Federal Arbitration Act, 9 U.S.C. §§ 1 et
          seq., not state or provincial law, shall govern the arbitrability of
          all disputes between Retirement Tracker and you regarding these Terms
          of Use and the Platform, including all provisions of this Section 19.
          Retirement Tracker and you agree, however, that the applicable state,
          federal or provincial law, as contemplated by Section 18 above, shall
          apply to and govern, as appropriate, any and all claims or causes of
          action, remedies, and damages arising between You and Retirement
          Tracker pursuant to this Section 19.
        </Paragraph>
        <Paragraph>
          NOTWITHSTANDING OUR GENERAL RIGHT TO MODIFY ANY PROVISIONS OF THESE
          TERMS AS PROVIDED ABOVE, IF WE MODIFY THE TERMS OF THIS "DISPUTE
          RESOLUTION – BINDING ARBITRATION" SECTION AFTER THE DATE YOU INITIALLY
          ACCEPTED THESE TERMS (OR MOST RECENTLY ACCEPTED ANY SUBSEQUENT CHANGES
          TO THESE TERMS), YOU MAY REJECT ANY SUCH CHANGE BY SENDING US WRITTEN
          NOTICE AT CONTACT@THERETIREMENTTRACKER.COM WITHIN 30 DAYS OF THE
          EFFECTIVE DATE OF SUCH CHANGE. BY REJECTING SUCH CHANGE(S), YOU ARE
          AGREE THAT, IF APPLICABLE, YOU WILL ARBITRATE ANY DISPUTE BETWEEN YOU
          AND RETIREMENT TRACKER ACCORDING TO THE TERMS OF THIS "DISPUTE
          RESOLUTION – BINDING ARBITRATION" SECTION AS OF THE DATE YOU FIRST
          ACCEPTED THESE TERMS (OR MOST RECENTLY ACCEPTED ANY SUBSEQUENT CHANGES
          TO THESE TERMS). IF NO ARBITRATION PROVISION TERMS APPLIED TO THE
          VERSION OF THESE TERMS THAT YOU MOST RECENTLY ACCEPTED, YOU WILL NOT
          BE BOUND BY THE ARBITRATION PROVISIONS OF THESE TERMS.
        </Paragraph>
        <Paragraph>
          You have the right to decline to be covered by the obligation to
          arbitrate set forth in this section by emailing
          contact@theretirementtracker.com from the email address You used to
          register your account with us and stating "I opt out of mandatory
          arbitration" in the subject line of the email within 30 days of
          initially registering your account with us. However, if You agreed to
          a prior version of these Terms of Use that allowed You to opt out of
          arbitration, Your previous choice to opt out or not opt out will
          remain binding.
        </Paragraph>
        <Paragraph style={{ wordBreak: "break-word" }}>
          The American Arbitration Association (the <b>"AAA"</b>) will
          administer the arbitration under its Consumer Arbitration Rules
          (https://adr.org/sites/default/files/Consumer_Rules_Web_1.pdf) and, as
          applicable, the Supplementary Procedures for Consumer Related Disputes
          (https://www.adr.org/sites/default/files/Consumer-Related%20Disputes%20Supplementary%20Procedures%20Sep%2015%2C%202005.pdf),
          or under the AAA's applicable rules adopted to replace such rules and
          procedures as are then in effect (collectively, the <b>"AAA Rules"</b>
          ). If an in-person hearing is required, the arbitration will be held
          in the United States county where You live or work, or any other
          location we agree to, and otherwise as determined by the AAA Rules. If
          the claim subject to arbitration does not exceed $10,000, then the
          arbitration will be conducted solely on the basis of documents that
          You and Retirement Tracker submit to the arbitrator, unless a party
          requests a hearing or the arbitrator determines that a hearing is
          necessary.
        </Paragraph>
        <Paragraph>
          The AAA Rules will govern payment of all arbitration fees, including
          your responsibility, if applicable, to pay any administrative and
          arbitrator fees. Retirement Tracker will not seek its attorneys' fees
          and costs in arbitration unless the arbitrator determines that Your
          claim is frivolous.
        </Paragraph>
        <Paragraph>
          Either You or Retirement Tracker may assert claims, if they qualify,
          in small claims court in any United States county where you live or
          work. Either party may bring a lawsuit solely for injunctive relief to
          stop unauthorized use or abuse of the Services, or intellectual
          property infringement (for example, trademark, trade secret,
          copyright, or patent rights) without first engaging in arbitration or
          the informal dispute-resolution process described above.
        </Paragraph>
        <Paragraph>
          As set forth in Section 21 below, nothing in this Agreement will
          prevent us from seeking injunctive relief in any court of competent
          jurisdiction as necessary to protect our proprietary interests.
        </Paragraph>
        <Heading level={3} color="text-weak">
          20. Class Action Waiver
        </Heading>
        <Paragraph>
          You agree that any arbitration or proceeding shall be limited to the
          claims or dispute between Retirement Tracker and you individually. To
          the full extent permitted by law, (i) no arbitration or proceeding
          shall be joined with any other; (ii) there is no right or authority
          for any claim or dispute to be arbitrated or resolved on a class
          action-basis or to utilize class action procedures; and (iii) there is
          no right or authority for any claim or dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.{" "}
          <b>
            You agree that you may bring claims against us only in your
            individual capacity and not as a plaintiff or class member in any
            purported class or representative proceeding.
          </b>
        </Paragraph>

        <Heading level={3} color="text-weak">
          21. Equitable Relief
        </Heading>
        <Paragraph>
          You acknowledge and agree that in the event of a breach or threatened
          violation of our intellectual property rights and confidential and
          proprietary information by you, Retirement Tracker will suffer
          irreparable harm and will therefore be entitled to injunctive relief
          to enforce this Agreement. We may, without waiving any other remedies
          under this Agreement, seek from any court having jurisdiction any
          interim, equitable, provisional, or injunctive relief that is
          necessary to protect our rights and property pending the outcome of
          the arbitration referenced above. You hereby irrevocably and
          unconditionally consent to the personal and subject matter
          jurisdiction of the federal and state courts in the State of
          California, County of Santa Clara for purposes of any such action by
          us.
        </Paragraph>
        <Heading level={3} color="text-weak">
          22. Downloading the App from the App Store
        </Heading>
        <Paragraph>
          The following terms apply when you download the App from Apple's App
          Store. These terms are in addition to all other terms contained in the
          Agreement.
        </Paragraph>
        <ul style={{ maxWidth: "600px" }}>
          <li style={{ margin: "3px 0" }}>
            You acknowledge and agree that (i) the Agreement is concluded
            between you and Retirement Tracker only, and not Apple; and (ii)
            Retirement Tracker, not Apple, is solely responsible for the App and
            content thereof. Your use of the App must comply with the App Store
            Terms of Service.
          </li>

          <li style={{ margin: "3px 0" }}>
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance and support services with respect to the App.
          </li>

          <li style={{ margin: "3px 0" }}>
            In the event of any failure of the App to conform to any applicable
            warranty, you may notify Apple, and Apple will refund the purchase
            price, if any, for the App to you. To the maximum extent permitted
            by applicable law, Apple will have no other warranty obligation
            whatsoever with respect to the App. As between Retirement Tracker
            and Apple, any other claims, losses, liabilities, damages, costs, or
            expenses attributable to any failure to conform to any warranty will
            be the sole responsibility of Retirement Tracker.
          </li>

          <li style={{ margin: "3px 0" }}>
            You acknowledge that, in the event of any third-party claim that the
            App or your possession and use of the App infringes that third
            party's intellectual property rights, as between Retirement Tracker
            and Apple, Retirement Tracker, not Apple, will be solely responsible
            for the investigation, defense, settlement, and discharge of any
            such intellectual property infringement claim to the extent required
            by the Agreement.
          </li>

          <li style={{ margin: "3px 0" }}>
            You acknowledge and agree that Apple, and Apple's subsidiaries, are
            third-party beneficiaries of the Agreement as related to your
            license of the App, and that, upon your acceptance of the terms and
            conditions of the Agreement, Apple will have the right (and will be
            deemed to have accepted the right) to enforce the Agreement as
            related to your license of the App against you as a third-party
            beneficiary thereof.
          </li>
        </ul>
        <Heading level={3} color="text-weak">
          23. Miscellaneous
        </Heading>
        <Paragraph>
          Our failure to act on or enforce any provision of the Agreement shall
          not be construed as a waiver of that provision or any other provision
          in this Agreement. No waiver shall be effective against us unless made
          in writing, and no such waiver shall be construed as a waiver in any
          other or subsequent instance. Except as expressly agreed by us and you
          in writing, this Agreement constitutes the entire Agreement between
          you and us with respect to the subject matter, and supersedes all
          previous or contemporaneous agreements, whether written or oral,
          between the parties with respect to the subject matter. The section
          headings are provided merely for convenience and shall not be given
          any legal import. This Agreement will inure to the benefit of our
          successors, assigns, licensees, and sublicensees. Unless otherwise
          stated in this Agreement should any provision of this Agreement be
          held invalid or unenforceable for any reason or to any extent, such
          invalidity or enforceability shall not in any manner affect or render
          invalid or unenforceable the remaining provisions of this Agreement,
          and the application of that provision shall be enforced to the extent
          permitted by law.
        </Paragraph>
      </Box>
    </Box>
  );
};
