import React, { useContext } from "react";
import { render } from "react-dom";

import { Box, Grommet, ResponsiveContext } from "grommet";
import { Faq, Legal, WebsiteNew } from "./pages";
import { trtTheme } from "./themes/trt";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const app = initializeApp({
  apiKey: "AIzaSyD9E7H5zmQMkwKJQvB6KjLaoHEBE2ORMTc",
  authDomain: "theretirementtracker-website.firebaseapp.com",
  projectId: "theretirementtracker-website",
  storageBucket: "theretirementtracker-website.appspot.com",
  messagingSenderId: "685745812019",
  appId: "1:685745812019:web:3f0eda646ca4a2e2df6946",
  measurementId: "G-P22SVWRQVN",
});
getAnalytics(app);

const PAGES_MAP = {
  website: WebsiteNew,
};

const MOBILE_PAGES_MAP = {
  website: WebsiteNew,
};

// needed to support mobile without having to account for browser header on mobile
// see https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
}

window.addEventListener("resize", appHeight);
appHeight();

const TRTApp = () => {
  const currentPage = "website";
  const size = useContext(ResponsiveContext);

  if (window.location.pathname === "/legal") {
    return <Legal />;
  } else if (window.location.pathname === "/faq") {
    return <Faq />;
  }
  let CurrentPage =
    size === "small"
      ? MOBILE_PAGES_MAP[currentPage] || PAGES_MAP[currentPage]
      : PAGES_MAP[currentPage];

  if (!CurrentPage) {
    CurrentPage =
      size === "small" ? MOBILE_PAGES_MAP.website : PAGES_MAP.website;
  }

  return <CurrentPage />;
};

const App = () => (
  <Grommet theme={trtTheme} full>
    <Box fill>
      <TRTApp />
    </Box>
  </Grommet>
);

render(<App />, document.getElementById("root"), () => {
  document.querySelector("body").removeAttribute("class");
});
