import React, { useContext, useMemo } from "react";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Stack,
  Text,
} from "grommet";
import {
  CircleIconHeading,
  LogoFull,
  RetirementEstimator,
} from "../components";
import {
  BarChart,
  CirclePlay,
  Currency,
  FacebookOption,
  Home,
  Twitter,
} from "grommet-icons";
import { useQueryParams } from "../hooks";

export const WebsiteNew = () => {
  const size = useContext(ResponsiveContext);
  const params = useQueryParams();
  const accessCode = params.get("utm_source");
  const appURL = useMemo(
    () =>
      `https://app.theretirementtracker.com/access?utm_source=${
        accessCode || "website"
      }`,
    [accessCode]
  );
  return (
    <Box fill style={{ maxWidth: "2560px" }} alignSelf="center">
      <Box flex={false} elevation="large">
        <Box
          background="url(/background.jpg)"
          responsive={false}
          margin={{ bottom: "large" }}
        >
          <Box
            style={{
              background: `linear-gradient(to bottom right, transparent 0%, #fff ${
                size === "small" ? "35%" : "50%"
              }, #fff 100%`,
              overflow: "hidden",
            }}
            flex
          >
            <Box
              direction="row"
              align="center"
              pad="medium"
              justify="between"
              responsive={false}
              flex={false}
            >
              <LogoFull size={size === "small" ? "medium" : "large"} />
              <Anchor
                href="https://app.theretirementtracker.com/login"
                label="Sign in"
                color="neutral-3"
                size={size !== "small" ? "large" : undefined}
                margin={{ right: "small" }}
              />
            </Box>
            <Box
              direction="row"
              align="center"
              justify="center"
              margin={{ top: "large", horizontal: "large" }}
              responsive={false}
              gap="small"
            >
              <Box
                align="center"
                basis={size === "small" ? undefined : "1/2"}
                flex={false}
              >
                <Box align="start" gap="medium" responsive={false} flex={false}>
                  <Box>
                    <Heading
                      margin="none"
                      size={size === "large" ? "large" : undefined}
                    >
                      Early
                    </Heading>
                    <Heading
                      margin="none"
                      size={size === "large" ? "large" : undefined}
                    >
                      Retirement
                    </Heading>
                    <Heading
                      margin="none"
                      size={size === "large" ? "large" : undefined}
                    >
                      Made Social
                    </Heading>
                  </Box>
                  <Paragraph
                    size={size === "small" ? "large" : "xlarge"}
                    color="text-weak"
                    margin="none"
                  >
                    Plan, track, and socialize early retirement with the only
                    tool you will ever need to keep you and your friends on
                    track
                  </Paragraph>
                  <Box direction="row" align="center" gap="medium">
                    <Button
                      target="_blank"
                      href={appURL}
                      primary
                      label="Sign up"
                    />
                    <Anchor
                      href="https://www.youtube.com/watch?v=o-qcgz2DaXk"
                      target="_blank"
                      icon={<CirclePlay />}
                      label="Watch Demo"
                    />
                  </Box>
                </Box>
              </Box>
              {size !== "small" && (
                <Box basis="1/2">
                  <Stack anchor="bottom-left">
                    <img
                      alt="dashboard desktop"
                      src="/dashboard-desktop.png"
                      width={size === "medium" ? "1000px" : "1200px"}
                    />
                    <img
                      alt="investment groups mobile"
                      src="/investment-group.png"
                      width={size === "medium" ? "700px" : "800px"}
                      style={{
                        marginLeft: "-30%",
                      }}
                    />
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          direction="row-responsive"
          background="light-1"
          pad={{ vertical: "xlarge", horizontal: "xlarge" }}
          gap="medium"
        >
          <Box basis={size !== "small" ? "1/2" : undefined} align="center">
            <Box align="start" gap="small">
              <Heading level={4} color="neutral-3" margin="none">
                PLAN WITH RESTIMATE
              </Heading>
              <Heading level={2} margin="none">
                How much do you need to retire?
              </Heading>
              <Paragraph
                size={size !== "small" ? "large" : undefined}
                color="text-weak"
              >
                Most people don't know the answer to this question. Our
                proprietary{" "}
                <Anchor
                  href="https://medium.com/@theretirementtracker/all-about-restimate-f40c222330b4"
                  target="_blank"
                >
                  Restimate
                </Anchor>{" "}
                calculator will provide all the numbers you need to stay on
                track every day, week, and years to come.
              </Paragraph>
              {size === "large" && (
                <Box alignSelf="center" margin={{ vertical: "small" }}>
                  <RetirementEstimator />
                </Box>
              )}
            </Box>
          </Box>
          <Box basis={size !== "small" ? "1/2" : undefined} align="center">
            <img
              alt="restimate plan"
              src="/restimate.png"
              width="100%"
              style={{
                borderRadius: "18px",
                maxWidth: "600px",
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.14)",
              }}
            />
          </Box>
        </Box>
        <Box
          direction="row-responsive"
          pad={{ vertical: "xlarge", horizontal: "xlarge" }}
          gap="medium"
        >
          <Box basis={size !== "small" ? "1/2" : undefined} align="center">
            <Box align="start" gap="small">
              <Heading level={4} color="neutral-3" margin="none">
                TRACK IT ALL
              </Heading>
              <Heading level={2} margin="none">
                A Net Worth Tracker for this Century
              </Heading>
              <Paragraph
                size={size !== "small" ? "large" : undefined}
                color="text-weak"
              >
                Connect your financial institutions once and leave the rest with
                us. We will update your information automatically multiple times
                a day. The information is organized in a way that is easy to
                understand and consistent across all your institutions.
              </Paragraph>
            </Box>
          </Box>
          <Box basis={size !== "small" ? "1/2" : undefined} align="center">
            <Box margin={{ vertical: "medium" }} align="center">
              <CircleIconHeading icon={Currency} color="neutral-3" />
              <Box
                direction="row"
                margin={{ top: size === "large" ? "-36px" : "-24px" }}
              >
                <CircleIconHeading
                  icon={BarChart}
                  color="accent-4"
                  margin={{ right: size === "large" ? "-20px" : "-12px" }}
                  style={{ position: "relative" }}
                />
                <CircleIconHeading icon={Home} color="accent-2" />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          direction="row-responsive"
          background="light-1"
          pad={{ vertical: "xlarge", horizontal: "xlarge" }}
          gap="medium"
        >
          <Box basis={size !== "small" ? "1/2" : undefined} align="center">
            <Box align="start" gap="small">
              <Heading level={4} color="neutral-3" margin="none">
                SOCIALIZE AMONG FRIENDS
              </Heading>
              <Heading level={2} margin="none">
                Who is the best investor?
              </Heading>
              <Paragraph
                size={size !== "small" ? "large" : undefined}
                color="text-weak"
              >
                Ever wondered how good your friends are with their investment
                choices? Create investment groups, add your friends, and
                automatically compete with them. See what they are buying and
                selling for a chance to catch up.
              </Paragraph>
            </Box>
          </Box>
          <Box basis={size !== "small" ? "1/2" : undefined} align="center">
            <img
              alt="restimate plan"
              src="/performance.png"
              width="100%"
              style={{
                borderRadius: "18px",
                maxWidth: "600px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.14)",
              }}
            />
          </Box>
        </Box>
        <Box pad={{ vertical: "xlarge", horizontal: "xlarge" }} gap="medium">
          <Box align="center">
            <Box align="center" gap="small">
              <Heading level={4} color="neutral-3" margin="none">
                WE NEVER SELL YOUR DATA
              </Heading>
              <Heading level={2} margin="none">
                Our Plans
              </Heading>
              <Paragraph
                textAlign="center"
                size={size !== "small" ? "large" : undefined}
                color="text-weak"
              >
                All our plans include a 7 days free trial. Cancel anytime, no
                questions asked. We will never upsell your information and/or
                offer you services you haven't requested for.
              </Paragraph>
            </Box>
          </Box>
          <Box align="center">
            <table
              style={
                size !== "small"
                  ? { marginLeft: "-8%", borderSpacing: "24px" }
                  : { borderSpacing: "12px" }
              }
            >
              <tr>
                {size !== "small" && <td></td>}
                <td align="center">
                  <Box
                    background="light-2"
                    pad="small"
                    round="small"
                    width="80px"
                  >
                    <Text weight="bold">BASIC</Text>
                  </Box>
                </td>
                <td align="center">
                  <Box
                    background="status-ok"
                    pad="small"
                    round="small"
                    width="80px"
                  >
                    <Text weight="bold">PRO</Text>
                  </Box>
                </td>
              </tr>
              <tr>
                {size !== "small" ? (
                  <td align="end">
                    <Box>
                      <Text size={size} weight="normal">
                        PRICE
                      </Text>
                      <Text color="text-xweak">Cancel Anytime</Text>
                    </Box>
                  </td>
                ) : undefined}
                <td>
                  <Box direction="row" align="center" justify="center">
                    <Text
                      size={size === "small" ? "medium" : "xlarge"}
                      weight="300"
                      margin={{ top: "4px" }}
                    >
                      $
                    </Text>
                    <Text size="28px" textAlign="end" weight="300">
                      0.00
                    </Text>
                    <Text
                      size={size === "small" ? "medium" : "large"}
                      weight="300"
                      margin={{ top: "4px" }}
                    >
                      /month
                    </Text>
                  </Box>
                </td>
                <td>
                  <Box direction="row" align="center" justify="center">
                    <Text
                      size={size === "small" ? "medium" : "xlarge"}
                      weight="300"
                      margin={{ top: "4px" }}
                    >
                      $
                    </Text>
                    <Text size="28px" textAlign="end" weight="300">
                      7.99
                    </Text>
                    <Text
                      size={size === "small" ? "medium" : "large"}
                      weight="300"
                      margin={{ top: "4px" }}
                    >
                      /month
                    </Text>
                  </Box>
                </td>
              </tr>
              <tr height="36px">
                {size !== "small" ? (
                  <td align="end">
                    <Box>
                      <Text size={size} weight="normal">
                        MANUAL INSTITUTIONS
                      </Text>
                      <Text color="text-xweak">
                        You have to update your data
                      </Text>
                    </Box>
                  </td>
                ) : undefined}
                <td align="center">
                  <Text
                    color="text-weak"
                    size={size === "large" ? "large" : undefined}
                  >
                    {size === "small"
                      ? "Unlimited manual institutions"
                      : "Unlimited"}
                  </Text>
                </td>
                <td align="center">
                  <Text
                    color="text-weak"
                    size={size === "large" ? "large" : undefined}
                  >
                    {size === "small"
                      ? "Unlimited manual institutions"
                      : "Unlimited"}
                  </Text>
                </td>
              </tr>
              <tr height="36px">
                {size !== "small" ? (
                  <td align="end">
                    <Box>
                      <Text size={size} weight="normal">
                        AUTOMATED INSTITUTIONS
                      </Text>
                      <Text color="text-xweak">
                        We update your data automatically
                      </Text>
                    </Box>
                  </td>
                ) : undefined}

                <td align="center">
                  <Text
                    color="text-weak"
                    size={size === "large" ? "large" : undefined}
                  >
                    {size === "small"
                      ? "1 automated institution"
                      : "1 institution"}
                  </Text>
                </td>
                <td align="center">
                  <Text
                    color="text-weak"
                    size={size === "large" ? "large" : undefined}
                  >
                    {size === "small"
                      ? "Unlimited automated institutions"
                      : "Unlimited"}
                  </Text>
                </td>
              </tr>
              <tr height="36px">
                {size !== "small" ? (
                  <td align="end">
                    <Box>
                      <Text size={size} weight="normal">
                        INVESTMENT GROUPS
                      </Text>
                      <Text color="text-xweak">Socialize with friends</Text>
                    </Box>
                  </td>
                ) : undefined}
                <td align="center">
                  <Text
                    color="text-weak"
                    size={size === "large" ? "large" : undefined}
                  >
                    {size === "small" ? "No investment group" : "No access"}
                  </Text>
                </td>
                <td align="center">
                  <Text
                    color="text-weak"
                    size={size === "large" ? "large" : undefined}
                  >
                    {size === "small"
                      ? "Unlimited investment groups"
                      : "Unlimited"}
                  </Text>
                </td>
              </tr>
            </table>
            <Button
              margin={{ top: "large" }}
              target="_blank"
              href={appURL}
              primary
              label="Sign up"
            />
          </Box>
        </Box>
        <Box background="neutral-3" pad={size === "small" ? "large" : "medium"}>
          <Box
            direction="row"
            align="start"
            justify="between"
            gap="medium"
            pad="small"
          >
            <Box>
              <LogoFull />
              <Box
                pad="small"
                gap="medium"
                margin={{ top: "medium" }}
                responsive={false}
              >
                <Anchor
                  href="mailto: contact@theretirementtracker.com"
                  color="white"
                  size="large"
                  target="_blank"
                >
                  Contact Us
                </Anchor>
                <Anchor
                  target="_blank"
                  href="/legal"
                  color="white"
                  size="large"
                >
                  Legal
                </Anchor>
                <Anchor target="_blank" href="/faq" color="white" size="large">
                  FAQ
                </Anchor>
              </Box>
            </Box>
            <Box
              direction="row"
              align="center"
              justify={size === "small" ? "start" : "end"}
              margin={size === "small" ? { vertical: "small" } : undefined}
              responsive={false}
            >
              <Text>Follow us on</Text>
              <Box direction="row" align="center">
                <Anchor
                  target="_blank"
                  href={
                    "https://www.facebook.com/profile.php?id=100067657789339"
                  }
                  icon={<FacebookOption />}
                />
                <Anchor
                  target="_blank"
                  href={"https://twitter.com/TRTApp"}
                  icon={<Twitter />}
                />
              </Box>
            </Box>
          </Box>
          <Paragraph margin={{ top: "large" }}>
            © 2021 The Retirement Tracker, Inc.
          </Paragraph>
        </Box>
      </Box>
    </Box>
  );
};
