import React, { useContext, useState } from "react";

import { Box, RangeInput, ResponsiveContext, Text, TextInput } from "grommet";
import { CurrencyInput } from "./currency-input";

export const RetirementEstimator = () => {
  const [retirementGoals, setRetirementGoals] = useState({
    retireIn: 10,
    retireWith: 10000,
  });
  const size = useContext(ResponsiveContext);
  return (
    <table>
      <tr>
        <td align="right">
          <Text
            weight="bold"
            style={{ "whiteSpace": "nowrap" }}
            margin="none"
            size={size}
          >
            Retire in
          </Text>
        </td>
        <td>
          <Box width="small" margin={{ horizontal: "small" }}>
            <RangeInput
              min="5"
              max="50"
              margin="none"
              value={retirementGoals.retireIn}
              onChange={({ target: { value: retireIn } }) =>
                setRetirementGoals({ ...retirementGoals, retireIn })
              }
            />
          </Box>
        </td>
        <td>
          <Box width="100px" pad={{ top: "small" }}>
            <TextInput
              type="number"
              value={retirementGoals.retireIn}
              onChange={({ target: { value: retireIn } }) =>
                setRetirementGoals({ ...retirementGoals, retireIn })
              }
            />
            <Text>years</Text>
          </Box>
        </td>
      </tr>
      <tr>
        <td align="right">
          <Text
            weight="bold"
            style={{ "whiteSpace": "nowrap" }}
            margin="none"
            size={size}
          >
            Retire With
          </Text>
        </td>
        <td>
          <Box width="small" margin={{ horizontal: "small" }}>
            <RangeInput
              min="1000"
              max="30000"
              margin="none"
              step={1000}
              value={retirementGoals.retireWith}
              onChange={({ target: { value: retireWith } }) =>
                setRetirementGoals({ ...retirementGoals, retireWith })
              }
            />
          </Box>
        </td>
        <td>
          <Box width="100px" pad={{ top: "small" }}>
            <CurrencyInput
              value={retirementGoals.retireWith}
              onChange={({ target: { value: retireWith } }) =>
                setRetirementGoals({ ...retirementGoals, retireWith })
              }
            />
            <Text>per month</Text>
          </Box>
        </td>
      </tr>
    </table>
  );
};
